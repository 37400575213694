export const play = () => ({
  type: "PLAY"
});

export const stop = () => ({
  type: "STOP"
});

export const pause = () => ({
  type: "PAUSE"
});
